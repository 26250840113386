/* A module containing functions for populating the content
of pop-up info boxes on the map. Functions that aren't exported from this module start with an underscore,
like this: _doStuff, and are at the top of the file. */

import * as constants from './constants.js';
import * as functions from './functions.js';
import * as layers from './layers.js';
import map from './map.js';

// round a value to dp decimal places
// or if dp is left out then do 3 decimal places for values < 1, and none for values >= 1
function _round(num, dp) {
    let multiplier = 1;
    if (dp != undefined) {
        multiplier = Math.pow(10, dp);
    } else if (num < 1) {
        multiplier = 1000;
    }
    return (
        Math.round((num + Number.EPSILON) * multiplier) / multiplier
    ).toLocaleString('en-US');
}

// A helper for the popup creation functions. Many of them use a background color
// for the popup title that matches the data value of the point generating the popup.
// If the color is dark enough, it needs to have the title and close button in
// white.
// bgcolor: the background color in question
// popup: the popup we are manipulating
// returns; the background color, as it needs to appear in the popup CSS
// side effect: styles the popup close button white
function _whiteTextForDarkPopups(bgColor, popup) {
    if (constants.darkCategoryColors.includes(bgColor)) {
        bgColor += '; color: #FFF;';

        // if the popup title is dark, make the popup's close button white when it exists.
        popup.once('open', function () {
            popup
                .getElement()
                .getElementsByClassName('mapboxgl-popup-close-button')[0]
                .classList.add('white');
        });
    }
    return bgColor;
}

// A helper for the hex popups. Given the value of the total energy in a hex,
// tell me what color it is in the map. Necessary because the data
// isn't plumbed through in mapbox's api.
// dataValue: the total energy in a hex, as an integer.
function _getHexColorFromDataValue(dataValue) {
    const breaks =
        constants.categoryBreaks[functions.colorRampType][
            functions.hexResFromZoom()
        ];
    let hex = constants.categoryColors[functions.colorRampType].at(-1);

    for (let i = 0; i < breaks.length; i++) {
        if (dataValue < breaks[i]) {
            hex = constants.categoryColors[functions.colorRampType][i];
            return hex;
        }
    }
    return hex;
}

// A helper for the hosting capacity popups. Given the load capacity of a line,
// tell me what color it is on the map.
// dataValue: the load capacity of the line, as an integer
function _getLineColorFromDataValue(dataValue) {
    let lineColor = constants.hostingCapacityColors.at(-1);
    for (let i = 0; i < constants.hostingCapacityBreaks.length; i++) {
        if (dataValue < constants.hostingCapacityBreaks[i]) {
            lineColor = constants.hostingCapacityColors[i];
            return lineColor;
        }
    }
    return lineColor;
}

// it's our date formatting object, so we can have one for the whole page, instead of one for each popup.
const dateFormatter = Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
});

// A helper function to get the hex data from another layer's popup handler.
function _getHexDataForPoint(point) {
    const data = map.queryRenderedFeatures(point, {
        layers: [layers.dynamicTilesLayer.id],
    });
    if (data && data[0] && data[0].properties) {
        return data[0].properties;
    } else {
        return null;
    }
}

// A helper function to build the hex popup content, so it can be called
// from other popup handlers if we want.
function _buildHexPopupContent(data) {
    const totalEnergy = _round(data.energy_total);
    const unmanagedPower = _round(data.power_unmanaged_total);
    const managedPower = _round(data.power_managed_total);

    return `
      <div class="popup-datum">
        <span class='varname'>Total Energy: </span><span class='attribute'>${totalEnergy}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Unmanaged Peak Power: </span> <span class='attribute'>${unmanagedPower}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Managed Peak Power: </span> <span class='attribute'>${managedPower}</span>
      </div>
      <i>Units are MWh/day</i>
  </div>`;
}

// A helper function that gets appropriately formatted hex popup content
// to include in other layers' popups if the map is zoomed in below hex 8
// and if that data exists.
function _getHexPopupContentForPoint(point) {
    let hexContent = '';
    if (functions.hexResFromZoom() >= 8) {
        let hexData = _getHexDataForPoint(point);
        if (hexData) {
            hexContent = `<hr>
                <div class="popup-secondary-title">Hexagon Totals</div>
                ${_buildHexPopupContent(hexData)}`;
        }
    }
    return hexContent;
}

// Given a popup for the transportation layer, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillTransportationPopup(event, popup) {
    const data = event.features[0].properties;
    const hexContent = _getHexPopupContentForPoint(event.point);

    const tract_name = data.tract_name ?? 'Unknown Census Tract';
    const html = `<div class='popup-title'>Transportation Disadvantage</div>
    <div class='popup-body'>
      <div class="popup-datum">
        <span class='attribute'>${tract_name}</span>
      </div>
      <p>People who live here have worse access to transportation than people in 90% of census tracts in the United States.</p>
      ${hexContent}
  </div>`;
    popup.setHTML(html);
}

// Given a popup for the pm25 layer, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillPm25Popup(event, popup) {
    const data = event.features[0].properties;
    const hexContent = _getHexPopupContentForPoint(event.point);
    const bgColor = _whiteTextForDarkPopups('#FBB800', popup);

    const tract_name = data.tract_name ?? 'Unknown Census Tract';
    let pm25 = 'Unknown';
    if (data.pm25) {
        pm25 = _round(parseFloat(data.pm25, 10), 2);
    }
    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Air Quality by Census Tract</div>
    <div class='popup-body'>
      <div class="popup-datum">
        <span class='attribute'>${tract_name}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>PM2.5 Air Quality:</span><span class='attribute'>${pm25}</span>
      </div>
      <i>PM2.5 is in micrograms per cubic meter</i>
      ${hexContent}
  </div>`;
    popup.setHTML(html);
}

// Given a popup for the justice40 layer, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillJustice40Popup(event, popup) {
    const data = event.features[0].properties;
    const hexContent = _getHexPopupContentForPoint(event.point);

    const bgColor = _whiteTextForDarkPopups(constants.softBlack, popup);
    const tract_name = data.tract_name ?? '';
    let pm25 = 'Unknown';
    if (data.pm25) {
        pm25 = _round(parseFloat(data.pm25, 10), 2);
    }
    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Justice40 Tract</div>
    <div class='popup-body'>
      <div class="popup-datum">
        <span class='attribute'>${tract_name}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>PM2.5 Air Quality:</span><span class='attribute'>${pm25}</span>
      </div>
      <i>PM2.5 is in &#956;g / cubic meter</i>
      ${hexContent}
  </div>`;
    popup.setHTML(html);
}

// Given a popup for the truck stops layer, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillTruckStopsPopup(event, popup) {
    const data = event.features[0].properties;
    const hexContent = _getHexPopupContentForPoint(event.point);

    const bgColor = _whiteTextForDarkPopups(constants.softBlack, popup);
    const name = data.NHS_Rest_S ?? 'Name Unknown';
    let parking_count = 0;
    if (data.prkngcount) {
        parking_count = parseInt(data.prkngcount, 10);
    }
    const road_name = data.Highway_Ro ?? 'Unknown';

    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Truck Stop</div>
    <div class='popup-body'>
      <div class="popup-datum">
        <span class='attribute'>${name}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Parking Spots:</span><span class='attribute'>${parking_count}</span>
      </div>
      <div class="popup-datum">
      <span class='varname'>Road:</span><span class="attribute">${road_name}</span>
      </div>
      ${hexContent}
  </div>`;
    popup.setHTML(html);
}

// Given a popup for the EV charging stations layer, use the data at that point in it
// to fill the popup with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillEVPopup(event, popup) {
    const data = event.features[0].properties;
    const hexContent = _getHexPopupContentForPoint(event.point);

    let confirmed_date = 'Unknown';
    if (data.date_last_confirmed) {
        confirmed_date = dateFormatter.format(
            Date.parse(data.date_last_confirmed)
        );
    }
    let lastRetrieved = 'Unknown';
    if (data.last_updated) {
        lastRetrieved = dateFormatter.format(Date.parse(data.last_updated));
    }
    let level2_ports = 'Unknown Number';
    if (data.level2_ports !== undefined) {
        level2_ports = parseInt(data.level2_ports, 10);
    }
    let dc_fast_ports = 'Unknown Number';
    if (data.dc_fast_ports !== undefined) {
        dc_fast_ports = parseInt(data.dc_fast_ports, 10);
    }

    let bgColor = constants.softBlack;
    if (dc_fast_ports > 0) {
        bgColor = constants.fastPortColor;
    }
    bgColor = _whiteTextForDarkPopups(bgColor, popup);

    const station_name = data.station_name ?? 'Unknown Name';
    const network = data.network ?? 'Unknown Charging Network';
    const connector_types = data.connector_types ?? 'Unknown';

    const html = `<div class='popup-title' style='background-color: ${bgColor}'>EV Charging Station</div>
    <div class='popup-body'>
      <div class="popup-datum">
        <span class='attribute'>${station_name}</span>
      </div>
      <div class="popup-datum">
        <span class="varname">${network}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Level 2 Ports:</span> <span class='attribute'>${level2_ports}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Fast Ports:</span> <span class='attribute'>${dc_fast_ports}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Connector Types:</span> <span class='attribute'>${connector_types}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Confirmed by Provider:</span> <span class='attribute'>${confirmed_date}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Retrieved by EPRI:</span> <span class='attribute'>${lastRetrieved}</span>
      </div>
      <div class="popup-datum">
        <span class="attribute">
          <a class="popup-link" target="_blank" rel="noopener" href="https://services9.arcgis.com/RHVPKKiFTONKtxq3/ArcGIS/rest/services/Alternate_Fuel/FeatureServer/0/query?outFields=*&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&returnZ=false&returnM=false&returnExceededLimitFeatures=true&f=html&where=station_id%3D${data.station_id}">
            View Source Data
          </a>
        </span>
      </div>
      ${hexContent}
    </div>`;
    popup.setHTML(html);
}

// Given a popup for the hosting capacity layer, use the data at that point in it
// to fill the popup with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillHostingCapacityPopup(event, popup) {
    const data = event.features[0].properties;
    const hexData = _getHexDataForPoint(event.point);

    let load_capacity = 'Unknown';
    if (data.load_capacity !== undefined) {
        load_capacity = parseFloat(data.load_capacity, 10);
    }
    let bgColor = _getLineColorFromDataValue(load_capacity);
    bgColor = _whiteTextForDarkPopups(bgColor, popup);
    let lastRetrieved = 'Unknown';
    if (data.last_retrieved) {
        lastRetrieved = dateFormatter.format(Date.parse(data.last_retrieved));
    }
    const lastUpdated = data.source_last_updated ?? 'Unknown';
    const source = data.source ?? 'Unknown';
    const line_name = data.line_name ?? 'Unknown';

    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Line Capacity</div>
    <div class='popup-body'>
      <div class="popup-datum">
      ${line_name}
      </div>
      <div class="popup-datum">
        <span class='varname'>Load Capacity (MW):</span><span class='attribute'>${load_capacity}</span>
      </div>
      <div class="popup-datum hosting-source">
        <span class='varname'>Data&nbsp;Source:</span> <span class='attribute'>${source}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Updated by Utility:</span> <span class='attribute'>${lastUpdated}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Retrieved by EPRI:</span> <span class='attribute'>${lastRetrieved}</span>
      </div>
      <hr>
      <div class="popup-secondary-title">Hexagon Totals</div>
      ${_buildHexPopupContent(hexData)}
    </div>`;
    popup.setHTML(html);
}

// Given a popup for the hex / main data layers, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillHexPopup(event, popup) {
    const data = event.features[0].properties;
    let bgColor = _getHexColorFromDataValue(data.energy_total);
    bgColor = _whiteTextForDarkPopups(bgColor, popup);
    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Totals for this Hexagon</div>
    <div class='popup-body'>${_buildHexPopupContent(data)}</div>`;
    popup.setHTML(html);
}

// Given a popup for the hex / main data layers, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillMultiFamilyPopups(event, popup) {
    const data = event.features[0].properties;
    const hexContent = _getHexPopupContentForPoint(event.point);
    const bgColor = _whiteTextForDarkPopups('#FBB800', popup);
    const tract_name = data.tract_name ?? 'Unknown Census Tract';
    let mfd_percent = 'Unknown';
    if (data.mfd_percent) {
        mfd_percent = _round(data.mfd_percent * 100);
    }
    let population = 'Unknown';
    if (data.total_pop) {
        population = data.total_pop;
    }
    let mfd_number = 'Unknown';
    if(data.multi_family) {
        mfd_number = data.multi_family;
    }
    const html = `<div class='popup-title' style='background-color: ${bgColor}'>MultiFamily Buildings</div>
    <div class='popup-body'>
      <div class="popup-datum">
        <span class='attribute'>${tract_name}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Households in Multifamily Buildings:</span><span class='attribute'>${mfd_number} (${mfd_percent}%)</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Residential Buildings in this Tract:</span></span class='attribute'>${population}</span>
      </div>
      ${hexContent}
    </div>`;
    popup.setHTML(html);
}

// Given a popup for the cost burdened households layer, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillCostBurdenedHouseholdsPopup(event, popup) {
    const data = event.features[0].properties;
    const hexContent = _getHexPopupContentForPoint(event.point);
    const bgColor = _whiteTextForDarkPopups('#FBB800', popup);

    const tract_name = data.tract_name ?? 'Unknown Census Tract';
    let percentage = 'Unknown';
    if(data.cost_burdened_percent) {
        percentage = _round(data.cost_burdened_percent * 100);
    }
    let total = 'Unknown';
    if(data.total_households) {
        total = data.total_households;
    }
    let n_cost_burdened = 'Unknown';
    if(data.n_cost_burdened) {
        n_cost_burdened = data.n_cost_burdened;
    }
    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Cost Burdened Households</div>
    <div class='popup-body'>
      <div class="popup-datum">
        <span class='attribute'>${tract_name}</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Cost Burdened Households: </span><span class='attribute'>${n_cost_burdened} (${percentage}%)</span>
      </div>
      <div class="popup-datum">
        <span class='varname'>Total Households in This Area: </span><span class='attribute'>${total}</span>
      </div>
      <p>Households that are both earning less than 80% of Housing and Urban Development’s Area Median Family Income and are spending more than 30% of their income on housing costs.</p>
      ${hexContent}
  </div>`;
    popup.setHTML(html);
}

// Add an event handler for popups to any layer.
// layer: the Mapbox layer to add the handler to
// contentFunction: the function that puts content in the popup needs to take two arguments:
//    - data: the data from the mapbox feature that just got clicked on
//    - popup: a mapboxgl.Popup object
// See fillHexPopup(data, popup) for an example
function addPopupHandler(layer, contentFunction) {
    map.on('click', layer, function (e) {
        functions.clearpopups();
        let popup = new mapboxgl.Popup({ maxWidth: '300px' }).setLngLat(
            e.lngLat
        );
        contentFunction(e, popup);
        popup.addTo(map);
    });

    // Change the cursor to a pointer when the mouse is over the data layer.
    map.on('mouseenter', layer, function () {
        map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', layer, function () {
        map.getCanvas().style.cursor = '';
    });
}

export {
    addPopupHandler,
    fillCostBurdenedHouseholdsPopup,
    fillEVPopup,
    fillHexPopup,
    fillHostingCapacityPopup,
    fillJustice40Popup,
    fillMultiFamilyPopups,
    fillPm25Popup,
    fillTransportationPopup,
    fillTruckStopsPopup,
};
